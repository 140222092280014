<template>
  <section
    :class="[
      { hasMap: content.showMap },
      { showMap: globalStore.state.mapState === State.OPENED },
    ]"
    class="list global-grid"
  >
    <div class="wrap">
      <ListStandardHeader
        :title="content.title"
        :show-title="content.showTitle"
        :list-type="globalStore.state.listType"
        :allowed-list-types="allowedListTypes"
        :hide-switch-small="content.showMap"
        @switch="switchType"
      />

      <ListStandardMain
        v-if="globalStore.state.listType === ListType.LIST"
        :content="content"
        :show-map="
          content.showMap && globalStore.state.mapState === State.OPENED
        "
        :show-footer="totalRecords > globalStore.state.pageSize"
      />

      <ListStandardTile
        v-else
        :content="content"
        :show-footer="totalRecords > globalStore.state.pageSize"
        class="items"
      />

      <ListMapSwitch
        :show-map="content.showMap"
        :map-open="globalStore.state.mapState === State.OPENED"
        @switch="switchMap"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ListType, State } from '@stores/globalStore';
import type { List } from './models';
import { MediaType } from '@assets/scss/variables';

defineProps<{
  content: List;
  allowedListTypes: string[];
  totalRecords: number;
}>();

const globalStore = useGlobalStore();

const redrawMapBus = useEventBus<never>('redrawMap');

const switchMap = async () => {
  globalStore.toggleMapState();

  if (globalStore.state.mapState === State.OPENED) {
    if (globalStore.state.listType === ListType.GALLERY) {
      globalStore.toggleListType();
    }
    await nextTick();
    redrawMapBus.emit();
  }
};

const switchType = () => {
  globalStore.toggleListType();

  if (
    globalStore.state.mediaType === (MediaType.SM || MediaType.TY) &&
    globalStore.state.listType === ListType.GALLERY &&
    globalStore.state.mapState === State.OPENED
  ) {
    globalStore.toggleMapState();
  }
};
</script>

<style src="./List.scss" scoped lang="scss"></style>
